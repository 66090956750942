import React from "react";
import { PageTitle } from "../style";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../../Redux/features/paymentSlice";
import { Description, DirectionContainer } from "../Dashboard/style";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { toast } from "react-toastify";
import { generateInvoice } from "../../../Apis/Private/Payment";
import InvoicePage from "./InvoicePage";
import { BsInfoCircleFill } from "react-icons/bs";

const GenerateInvoice = () => {
  const { sessions, semesters, feeSchedule } = useAppSelector(selectUtil);
  const {
    sessionId,
    semesterId,
    feeScheduleId,
    generatingInvoice,
    generatedInvoice,
  } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleGenerate = () => {
    if (feeScheduleId && sessionId) {
      generateInvoice(feeScheduleId, sessionId, semesterId);
    } else {
      toast.error("Please fill all fields");
    }
  };

  const FilteredFeeSheduleById = feeSchedule?.filter(
    (x: any) => x?.feeScheduleId === feeScheduleId
  );

  const currentFeeShedule = FilteredFeeSheduleById[0] || {};


  return (
    <>
      <PageTitle>Generate Invoice</PageTitle>
      <DirectionContainer>
        <Description>
          <BsInfoCircleFill color="gold" size={20} />
          <span>
            Some fees can be paid in installment by selecting the semester if
            available
          </span>
        </Description>
      </DirectionContainer>
      <div className="flex-centered-items flex-wrap">
        <UnderlinedSelector
          dropdownItems={feeScheduleFilter}
          title={`Fee Scheduled`}
          handleDropdownSelect={handleSelect}
          name="feeScheduleId"
          value={feeScheduleId}
        />
        <UnderlinedSelector
          dropdownItems={sessionFilter}
          title={`Session`}
          handleDropdownSelect={handleSelect}
          name="sessionId"
          value={sessionId}
        />
        {currentFeeShedule?.canPayInstallment && (
          <UnderlinedSelector
            dropdownItems={semesterFilter}
            title={`Semester`}
            handleDropdownSelect={handleSelect}
            name="semesterId"
            value={semesterId}
          />
        )}
      </div>
      <SmallButton
        buttonText="Generate invoice"
        handleClick={handleGenerate}
        loading={generatingInvoice}
      />
      {generatedInvoice && <InvoicePage />}
    </>
  );
};

export default GenerateInvoice;
