import { useEffect, useState } from "react";
import { PiArrowRightThin } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";
import { getPaymentReceiptUnAuthenticated } from "../../../Apis/Private/Payment";
import {
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
  SectionTitle,
  Table,
  TableHead,
  TableData,
  TableRow,
} from "../styles";
import ReactPrint from "../../ReactPrint";
import { MdOutlineError } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import {
  Description,
  DirectionContainer,
} from "../../PrivatePages/Dashboard/style";
import { ItemsCenterJustifyBtw, PageTitle } from "../../PrivatePages/style";
import Overlay from "../../../Loaders/Overlay";

const UnAuthorizedPaymentReceipt = () => {
  const [fetchError, seFetchError] = useState();
  const { id } = useParams();
  const { paymentReceipt, gettingPaymentReceipt } =
    useAppSelector(selectPayment);

  // const {}
  useEffect(() => {
    getPaymentReceiptUnAuthenticated(Number(id), seFetchError);
  }, [id]);

  return (
    <>
      <PageTitle>Payment Invoice</PageTitle>
      <LongTransparentCard>
        <LongWhiteCard>
          {gettingPaymentReceipt ? (
            <Overlay loadingText="Getting Payment Invoice..." />
          ) : (
            <>
              {paymentReceipt?.payment ? (
                <ReactPrint
                  pay={true}
                  etransactPaymentLink={paymentReceipt?.etransact?.paymentUrl}
                >
                  <ReceiptContainer>
                    <ReceiptHeader>
                      <img src="/assets/images/PNG/logo.png" alt="logo" />
                      <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                      <SchoolPhone>
                        {paymentReceipt?.payment?.feeSchedule?.feeScheduleName}{" "}
                        Invoice
                      </SchoolPhone>
                    </ReceiptHeader>
                    <ReceiptBody>
                      <ItemsCenterJustifyBtw className="align-start">
                        <div style={{ width: "100%" }}>
                          <SectionTitle>From</SectionTitle>
                          <p>
                            {paymentReceipt?.payment?.person?.firstName +
                              paymentReceipt?.payment?.person?.lastName +
                              paymentReceipt?.payment?.person?.otherName}
                          </p>
                          <p>{paymentReceipt?.payment?.person?.emailAddress}</p>
                          <p>
                            {paymentReceipt?.payment?.person?.contactAddress}
                          </p>
                        </div>
                        <PiArrowRightThin size={30} width={100} />
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <SectionTitle>To</SectionTitle>
                              <span style={{ textTransform: "uppercase" }}>Godfrey Okoye University</span>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payment type: </SectionTitle>{" "}
                              <span>
                                {
                                  paymentReceipt?.payment?.feeSchedule
                                    .feeScheduleName
                                }
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>
                                Payment ID/Invoice Number:{" "}
                              </SectionTitle>{" "}
                              <span>{+paymentReceipt?.payment?.paymentId}</span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payee ID: </SectionTitle>
                              <span>
                                {
                                  paymentReceipt?.payment?.person
                                    ?.prospectiveStudent?.applicationNumber
                                }
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Invoice Date: </SectionTitle>
                              <span>
                                {moment(
                                  paymentReceipt?.payment?.transactionDate
                                ).format("DD MMM YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <Table>
                        <TableRow>
                          <TableHead>Item</TableHead>
                          <TableHead>Quantity</TableHead>
                          <TableHead>Unit Price(#)</TableHead>
                          <TableHead>Total Price(#)</TableHead>
                        </TableRow>
                        <TableRow>
                          <TableData>
                            {
                              paymentReceipt?.payment?.feeSchedule
                                .feeScheduleName
                            }
                          </TableData>
                          <TableData>1</TableData>
                          <TableData>
                            {
                              paymentReceipt?.payment?.feeSchedule
                                ?.feeDetails[0].feeAmount
                            }
                          </TableData>
                          <TableData>
                            {
                              paymentReceipt?.payment?.feeSchedule
                                ?.feeDetails[0].feeAmount
                            }
                          </TableData>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableHead>SubTotal: </TableHead>
                          <TableData>
                            {
                              paymentReceipt?.payment?.feeSchedule
                                ?.feeDetails[0].feeAmount
                            }
                          </TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>VAT: </TableHead>
                          <TableData>0.00</TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>Total: </TableHead>
                          <TableData>
                            {
                              paymentReceipt?.payment?.feeSchedule
                                ?.feeDetails[0].feeAmount
                            }
                          </TableData>
                        </TableRow>
                      </Table>
                    </ReceiptBody>
                    <DirectionContainer>
                      <Description>
                        <BsInfoCircleFill color="red" size={20} />
                        Note: you will be charged #500 for the services
                      </Description>
                    </DirectionContainer>
                  </ReceiptContainer>
                </ReactPrint>
              ) : (
                <Description>
                  <MdOutlineError color="red" size={20} />
                  {fetchError ||
                    `Payment receipt not
                  available`}
                </Description>
              )}
            </>
          )}
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default UnAuthorizedPaymentReceipt;
