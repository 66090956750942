import { ItemsCenterJustifyBtw } from "../style";
import { PiArrowRightThin } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";
import {
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
  SectionTitle,
  Table,
  TableHead,
  TableData,
  TableRow,
} from "./styles";
import ReactPrint from "../../ReactPrint";
import { Description, DirectionContainer } from "../Dashboard/style";
import { BsInfoCircleFill } from "react-icons/bs";

const InvoicePage = () => {
  const { generatedInvoice } = useAppSelector(selectPayment);

  return (
    <LongTransparentCard>
      <LongWhiteCard>
        <ReactPrint
          pay={true}
          etransactPaymentLink={generatedInvoice?.etransact?.paymentUrl}
        >
          <ReceiptContainer>
            <ReceiptHeader>
              <img src="/assets/images/PNG/logo.png" alt="logo" />
              <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
              <SchoolPhone>
                {generatedInvoice?.payment?.feeSchedule?.feeScheduleName}{" "}
                receipt
              </SchoolPhone>
            </ReceiptHeader>
            <ReceiptBody>
              <ItemsCenterJustifyBtw className="align-start">
                <div style={{ width: "100%" }}>
                  <SectionTitle>From</SectionTitle>
                  <p>
                    {generatedInvoice?.payment?.person?.firstName + " " + 
                      generatedInvoice?.payment?.person?.lastName + " " +
                      generatedInvoice?.payment?.person?.otherName}
                  </p>
                  <p>{generatedInvoice?.payment?.person?.emailAddress}</p>
                  <p>{generatedInvoice?.payment?.person?.contactAddress}</p>
                </div>
                <PiArrowRightThin size={30} width={100} />
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <SectionTitle>To</SectionTitle>
                    <span style={{textTransform: "uppercase"}}>Godfrey Okoye University</span>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SectionTitle>Payment type: </SectionTitle>{" "}
                      <span>
                        {generatedInvoice?.payment?.feeSchedule.feeScheduleName}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SectionTitle>Payment ID/Invoice Number: </SectionTitle>{" "}
                      <span>{+generatedInvoice?.payment?.paymentId}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SectionTitle>Payee ID: </SectionTitle>
                      <span>
                        {
                          generatedInvoice?.payment?.person?.prospectiveStudent
                            ?.applicationNumber
                        }
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SectionTitle>Invoice Date: </SectionTitle>
                      <span>
                        {moment(
                          generatedInvoice?.payment?.transactionDate
                        ).format("DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </ItemsCenterJustifyBtw>
              <Table>
                <TableRow>
                  <TableHead>Item</TableHead>
                  <TableHead>Quantity</TableHead>
                  <TableHead>Unit Price(#)</TableHead>
                  <TableHead>Total Price(#)</TableHead>
                </TableRow>
                <TableRow>
                  <TableData>
                    {generatedInvoice?.payment?.feeSchedule.feeScheduleName}
                  </TableData>
                  <TableData>1</TableData>
                  <TableData>
                    {
                      generatedInvoice?.payment?.feeSchedule?.feeDetails[0]
                        .feeAmount
                    }
                  </TableData>
                  <TableData>
                    {
                      generatedInvoice?.payment?.feeSchedule?.feeDetails[0]
                        .feeAmount
                    }
                  </TableData>
                </TableRow>
              </Table>
              <Table>
                <TableRow>
                  <TableHead>SubTotal: </TableHead>
                  <TableData>
                    {
                      generatedInvoice?.payment?.feeSchedule?.feeDetails[0]
                        .feeAmount
                    }
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableHead>VAT: </TableHead>
                  <TableData>0.00</TableData>
                </TableRow>
                <TableRow>
                  <TableHead>Total: </TableHead>
                  <TableData>
                    {
                      generatedInvoice?.payment?.feeSchedule?.feeDetails[0]
                        .feeAmount
                    }
                  </TableData>
                </TableRow>
              </Table>
            </ReceiptBody>
            <DirectionContainer>
              <Description>
                <BsInfoCircleFill color="red" size={20} />
                Note: you will be charged #500 for the services
              </Description>
            </DirectionContainer>
          </ReceiptContainer>
        </ReactPrint>
      </LongWhiteCard>
    </LongTransparentCard>
  );
};

export default InvoicePage;
